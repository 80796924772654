/** -------------------------------------------------------- FOOTER -------------------------------------------------------- **/
.container-footer-cta, .started-cta-directory {
    postion: relative;
    display: flex;
    align-items: center;
    min-height: 300px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1088px;
    width: 100%;
    position:relative;
  }
  
  .container-footer-cta, .started-cta-directory {
    justify-content: space-between;
  }
  
  .started-cta-info h1 {
    color:var(--clr-green100);
    font-size:56px;
  }
  
  .started-cta-info h3 {
    color:var(--clr-gray100);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  
  .container-started-cta-btn {
    padding-right: 120px;
  }
  
  #footer-cta-btn {
    padding: 15px 30px 20px;
    border-radius: 100px;
    background-color:var(--clr-gray100);
    font-weight: bold;
    line-height: 20px;
    color: var(--clr-primary);
    font-size: 19px;
    text-decoration: none;
  }
  
  .started-cta-directory {
    align-items: flex-start;
  }
  
  .container-footer-links {
    display: flex;
    align-items: flex-start;
  }
  
  .footer-links {
    padding-right: 85px;
    font-size: 18px;
  }
  
  
  .footer-link, .footer-link a {
    list-style: none;
    text-decoration:none;
    color:var(--clr-gray100);
    margin-bottom:15px;
  }
  
  
  .top-footer-link {
    color: var(--clr-green100) !important;
    font-weight: bold;
    font-size: 20px;
  }
  
  .rights {
    padding: 20px 100px 0px 0px;
    color:var(--clr-gray100)
  }