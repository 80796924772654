/** -------------------------------------------------------- NAVIGATION -------------------------------------------------------- **/
.nav {
    box-shadow: 1px 1px 24px 0 rgb(6 26 54 / 12%);
    z-index:  100;
    width: 100%;
    position:fixed;
    background:var(--clr-primary);
    top: 0px;
  }
  
  
  
  .container-desktop-nav {
      padding: 0 1rem;
      height: 76px;
      min-height: 76px;
  }
  
  .nav-whole {
    display: flex;
    position: relative;
    align-items: center;
    min-height: 3rem;
    margin: 0 auto;
    max-width: 1088px;
    height: 100%;
    width:100%;
    justify-content: space-between;
    list-style:none;
  }
  
  .nav-header {
    align-items: center;
    display:  flex;
    margin-right: 1rem;
    z-index: 120;
    min-height: 76px;
    height:   100%;
  }
  
  .nav-main a, .nav-main, .nav-cta a, .nav-cta {
    list-style: none;
    text-decoration:none;
    color:black;
  }
  
  .nav-main, .nav-cta {
    display:flex;
    align-text: center;
    height:100%;
  }
  
  .nav-block {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transpa0;
  }
  
  #logo {
    padding-left:0px;
  }
  
  #start-btn {
    padding: 10px 25px 9px;
    border-radius: 100px;
    background-color: var(--clr-green100);
    font-size: 20px;
    line-height: 20px;
    color: var(--clr-primary);
    font-size: 16px;
  }