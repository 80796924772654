/* -------------------------------------------------------- VARIABLES & GEN -------------------------------------------------------- */
:root {
    --clr-primary: #FFFFFF;
    --clr-primary-hover: #005f33;
    --clr-green100: #005f33;
    --clr-mutuall-gray200: #cfd8dc;
    --clr-gray100: #A6AEB8;
    --clr-gray200: #77738B;
    
  
  }
  
  html {
    width: 100%;
    overflow-x: hidden !important;
    overflow-y: visible !important;
    box-sizing: border-box;
  }
  
  
  body {
    font-family: 'Lato', sans‑serif;
    background: var(--clr-primary);
    margin:0;
  }
  
  ul {
  padding-inline-start: 0px;
  }