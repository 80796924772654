/** BODY CONTENT **/
.main-container{
    position: relative;
    display: flex;
    align-items: center;
    min-height: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1120px;
  }